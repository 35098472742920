var exports = {};
exports = u;

function u(type, props, value) {
  var node;

  if ((value === null || value === undefined) && (typeof props !== "object" || Array.isArray(props))) {
    value = props;
    props = {};
  }

  node = Object.assign({
    type: String(type)
  }, props);

  if (Array.isArray(value)) {
    node.children = value;
  } else if (value !== null && value !== undefined) {
    node.value = String(value);
  }

  return node;
}

export default exports;